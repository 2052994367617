.sidebar {
  position: fixed;
  z-index: 1000;
  top: 0;
  width: 335px;
  height: 100%;
  background-color: #343a40;
  // background: #343a40;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  -ms-box-shadow: 0 16px 40px 0 rgba(0, 0, 0, 0.0392156863);
  -o-box-shadow: 0 16px 40px 0 rgba(0, 0, 0, 0.0392156863);
  -webkit-box-shadow: 0 16px 40px 0 rgb(0 0 0 / 4%);
  box-shadow: 0 16px 40px 0 rgb(0 0 0 / 4%);
  // border-right: 1px solid #e0e0e0;
  overflow: auto;

  &-content {
    padding: 20px;
  }

  &-logo {
    margin-bottom: 20px;
    & > svg {
      width: 100%;
    }
  }

  &-mask {
    position: fixed;
    inset: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.8);
    z-index: 999;
  }
}

.navbar {
  background-color: #343a40;
  padding: 0 30px;
  min-height: 92px;
  left: auto;
  width: calc(100% - 335px);
  display: inline-block;
  z-index: 99;
  margin: 0;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  -ms-box-shadow: 0 16px 40px 0 rgba(0, 0, 0, 0.0392156863);
  -o-box-shadow: 0 16px 40px 0 rgba(0, 0, 0, 0.0392156863);
  -webkit-box-shadow: 0 16px 40px 0 rgb(0 0 0 / 4%);
  box-shadow: 0 16px 40px 0 rgb(0 0 0 / 4%);
  border-bottom: 1px solid #e0e0e0;
  position: fixed;
  right: 0;
  top: 0;
  display: flex;
  align-items: center;
  &-content {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
  }

  &-menu {
    display: none;
    margin-right: auto;
  }

  @media (max-width: 1024px) {
    width: 100%;

    &-menu {
      display: block;
    }
  }
}

.content {
  // overflow: hidden;
  margin-left: 335px;
  padding: 110px 15px 0;
  min-height: 100vh;
  background-color: #fafbfe;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;

  @media (max-width: 1024px) {
    margin-left: 0;
    padding: 130px 15px 0;
    padding-bottom: 40px;
  }
}

.text-500 > * {
  color: #fff;
}

.w-100p {
  width: 100% !important;
}

.buttons {
  margin-bottom: 20px;

  &-list {
    list-style: none;
    display: flex;
    flex-wrap: wrap;

    &__item {
      margin-right: 15px;
      margin-bottom: 10px;
    }

    @media (max-width: 1024px) {
      gap: 10px;
      justify-content: center;
    }
  }
}

.pagination {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  // margin-right: 50px;
}

.MuiTablePagination-actions {
  opacity: 0;
}

.formField {
  margin: 16px 0 8px 0 !important;
  width: 100% !important;
}

.loader {
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.snackbar > div {
  background-color: transparent;
  padding: 0;
}

.page-contain {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  // min-height: 100vh;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  // background: #e7f3f1;
  border: 0.75em solid transparent;
  padding: 2em;
  font-family: "Open Sans", sans-serif;

  @media (max-width: 1024px) {
    padding: 0;
    align-items: center;
    justify-content: center;
    border: none;
    gap: 30px;
  }
}

.data-card {
  display: flex;
  flex-direction: column;
  cursor: pointer;
  max-width: 20.75em;
  // min-height: 20.75em;
  overflow: hidden;
  border-radius: 0.5em;
  text-decoration: none;
  background: white;
  margin: 1em;
  padding: 2.75em 2.5em;
  box-shadow: 0 1.5em 2.5em -0.5em rgba(#000000, 0.1);
  transition: transform 0.45s ease, background 0.45s ease;

  h3 {
    color: #2e3c40;
    font-size: 3.5em;
    font-weight: 600;
    line-height: 1;
    text-align: center;
    padding-bottom: 0.5em;
    margin: 0 0 0.142857143em;
    border-bottom: 2px solid #753bbd;
    transition: color 0.45s ease, border 0.45s ease;
  }

  h4 {
    color: #627084;
    text-align: center;
    text-transform: uppercase;
    font-size: 1.125em;
    font-weight: 700;
    line-height: 1;
    letter-spacing: 0.1em;
    margin: 0 0 1.777777778em;
    transition: color 0.45s ease;
  }

  ul {
    li {
      transition: color 0.45s ease;
    }
  }

  p {
    opacity: 0;
    color: #ffffff;
    font-weight: 600;
    line-height: 1.8;
    margin: 0 0 1.25em;
    transform: translateY(-1em);
    transition: opacity 0.45s ease, transform 0.5s ease;
  }

  .link-text {
    display: block;
    color: #753bbd;
    font-size: 1.125em;
    font-weight: 600;
    line-height: 1.2;
    margin: auto 0 0;
    transition: color 0.45s ease;

    svg {
      margin-left: 0.5em;
      transition: transform 0.6s ease;

      path {
        transition: fill 0.45s ease;
      }
    }
  }

  &:hover {
    background: #1565c0;
    transform: scale(1.02);

    ul {
      li {
        color: #fff;
      }
    }

    h3 {
      color: #ffffff;
      border-bottom-color: #a754c4;
    }

    h4 {
      color: #ffffff;
    }

    p {
      opacity: 1;
      transform: none;
    }

    .link-text {
      color: #ffffff;

      svg {
        animation: point 1.25s infinite alternate;

        path {
          fill: #ffffff;
        }
      }
    }
  }

  @media (max-width: 1024px) {
    margin-left: 0;
    margin-right: 0;
  }
}

@keyframes point {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(0.125em);
  }
}
